import React, { useState, useRef } from "react";
import { Key } from "../../utils/envAccess";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import { useEffect } from "react";

const mapContainerStyle = {
  width: "100%",
  height: "250px",
};

const defaultZoom = 8;
const libraries = ["geometry", "places", "drawing"];

function Map({ markers, setMarkers, defaultMarkers }) {
  const mapRef = useRef(null);
  const autocompleteRef = useRef(null);
  const [center, setCenter] = useState();

  useEffect(() => {
    if (defaultMarkers && defaultMarkers.length > 0) {
      const defaultMarker = defaultMarkers[0];
      if (!center) {
        setCenter({
          lat: 53.520611,
          lng: -113.4627,
        });
      }
      //setMarkers([defaultMarker]);
      //setMarkers((prevMarkers) => [prevMarkers, defaultMarker]);
    }
  }, [defaultMarkers, markers, setMarkers]);

  const handleMapClick = (event) => {
    const newMarker = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      info: null,
    };
    //setMarkers([...markers, newMarker]) for setting up multiple Markers;
    setCenter({
      lat: newMarker.lat,
      lng: newMarker.lng,
    });
    setMarkers([newMarker]);
  };

  const handlePlaceSelect = (place) => {
    if (!place || !place.formatted_address) {
      alert("Empty field");
      setMarkers([]);
      return;
    } else {
      const newMarker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        info: null,
      };
      setCenter({
        lat: newMarker.lat,
        lng: newMarker.lng,
      });
      setMarkers([newMarker]);
      console.log("Selected location:", newMarker.lat, newMarker.lng);
    }
  };

  return (
    <LoadScript googleMapsApiKey={Key} libraries={libraries}>
      <div>
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={() =>
            handlePlaceSelect(autocompleteRef.current.getPlace())
          }
        >
          <input
            ref={mapRef}
            type="text"
            placeholder="Search for a location"
            className="mb-1"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `100%`,
              height: `42px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
          />
        </Autocomplete>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={defaultZoom}
          onClick={handleMapClick}
          streetViewControl={false}
          fullscreenControl={false}
          mapTypeControl={false}
        >
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={{ lat: marker.lat, lng: marker.lng }}
            />
          ))}
        </GoogleMap>
      </div>
    </LoadScript>
  );
}

export default Map;
