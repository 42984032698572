import { NavLink } from "react-router-dom";
import fertilityImage from "../../assets/images/auth/fertilityImage.svg";
import { useState } from "react";
import PatientSignup from "./SignUpPages/PatientSignup";
import DoctorSignup from "./SignUpPages/DoctorSignup";

const Register = () => {
  const [userRole, setUserRole] = useState(null);

  const setRole = (role) => {
    setUserRole(role.toString());
  };

  return (
    <>
      {!userRole ? (
        <div className="bg-gradient-white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0">
                  <div className="card-body p-0">
                    <div className="row">
                      {/* <div className="col-lg-6 d-none d-lg-block bg-login-image"></div> */}
                      <div className="col-lg-12 pt-md-3">
                        <div className="p-4">
                          <div className="text-center">
                            <div className="row">
                              <div className="col-lg-12 py-4 mb-4 text-bold bg-login-imageF">
                                <img
                                  src={fertilityImage}
                                  alt="loginImage"
                                  style={{ width: "80%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-center">
                            <div className="row justify-content-center">
                              <div className="col-lg-10">
                                <h1 className="h4 text-black mb-4 ">
                                  Join My Fertility Lab as a,
                                </h1>
                                <div className="col-md-6 offset-md-3">
                                  {/* <button
                                    type="button"
                                    className="btn btn-primary btn-user btn-block"
                                    onClick={() => setRole(2)}
                                  >
                                    <span>Coach</span>
                                  </button> */}
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-user btn-block"
                                    onClick={() => setRole(3)}
                                  >
                                    <span>Doctor</span>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-user btn-block"
                                    onClick={() => setRole(4)}
                                  >
                                    <span>Patient</span>
                                  </button>
                                </div>
                              </div>
                              {/* <div className="col-lg-8">
                                <div className="form-group">
                                  <label
                                    className="form-label ml-1 text-bold"
                                    htmlFor="exampleInputSRole"
                                  >
                                    Join My Fertility Lab as a,
                                  </label>
                                  <select
                                    className="form-control"
                                    id="exampleInputSComp"
                                  >
                                    <option disabled="true" value="">
                                      Select
                                    </option>
                                    <option value="3">Doctor</option>
                                    <option value="4">Patient</option>
                                  </select>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-user btn-block col-6 offset-3"
                                >
                                  <span>Proceed</span>
                                </button>
                              </div> */}
                            </div>
                          </div>
                          <hr />
                          <div className="text-center">
                            <span style={{ fontSize: "13px" }}>
                              Already have an account?{" "}
                            </span>
                            <NavLink className="small" to="/">
                              Sign In!
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {userRole === "2" && <DoctorSignup userRole={userRole} />}
          {userRole === "3" && <DoctorSignup userRole={userRole} />}
          {userRole === "4" && <PatientSignup userRole={userRole} />}
        </>
      )}
    </>
  );
};

export default Register;
