import { NavLink, Navigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { postLogin } from "../redux/AuthController";
import Spinner from "react-bootstrap/Spinner";
import fertilityImage from "../../assets/images/auth/fertilityImage.svg";

const initialValues = {
  email: "",
  password: "",
};

const validateLogin = Yup.object().shape({
  email: Yup.string()
    .min(3, "User Name must be at least 3 characters")
    .required("Please enter User Name or Email"),
  password: Yup.string()
    .min(3, "Password must be at least 3 characters")
    .required("Please enter password"),
});

function Login() {
  const dispatch = useDispatch();
  const { values, handleBlur, handleChange, handleSubmit, errors } = useFormik({
    initialValues: initialValues,
    validationSchema: validateLogin,
    onSubmit: (values) => {
      console.log(values);
      try {
        dispatch(postLogin(values));
      } catch (error) {
        console.log("login-page api call error: " + error);
      }
    },
  });

  const data = useSelector((state) => state?.authentication?.userAuth);

  const { loading } = useSelector((state) => state?.authentication);

  if (data && data.id) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="bg-gradient-white">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0">
              <div className="card-body p-0">
                <div className="row">
                  {/* <div className="col-lg-6 d-none d-lg-block bg-login-image"></div> */}
                  <div className="col-lg-12 pt-md-3">
                    <div className="p-4">
                      <div className="text-center">
                        <div className="row">
                          <div className="col-lg-12 mb-4 text-bold bg-login-imageF">
                            <img
                              src={fertilityImage}
                              alt="loginImage"
                              style={{ width: "70%" }}
                            />
                          </div>
                        </div>
                        {/* <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1> */}
                      </div>
                      <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                          <form className="user" onSubmit={handleSubmit}>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id="exampleInputEmail"
                                aria-describedby="emailHelp"
                                placeholder="UserName or Email"
                                value={values.email}
                                onBlur={handleBlur("email")}
                                onChange={handleChange("email")}
                              />
                              {errors.email && (
                                <small className="text-danger">
                                  {errors.email}
                                </small>
                              )}
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                autoComplete="off"
                                className="form-control form-control-user"
                                id="exampleInputPassword"
                                placeholder="Password"
                                value={values.password}
                                onBlur={handleBlur("password")}
                                onChange={handleChange("password")}
                              />
                              {errors.password && (
                                <small className="text-danger">
                                  {errors.password}
                                </small>
                              )}
                            </div>
                            {/* <div className="form-group">
                          <div className="custom-control custom-checkbox small">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div> */}
                            <button
                              type="submit"
                              className="btn btn-primary btn-user btn-block col-6 offset-3"
                              disabled={loading}
                            >
                              {loading ? (
                                <span>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  {/* Loading... */}
                                </span>
                              ) : (
                                <span>Sign In</span>
                              )}
                            </button>
                            {/* <hr />
                        <button className="btn btn-primary btn-user btn-block">
                          <i className="fab fa-google fa-fw"></i> Login with
                          Google
                        </button>
                        <button className="btn btn-primary btn-user btn-block">
                          <i className="fab fa-facebook-f fa-fw"></i> Login with
                          Facebook
                        </button> */}
                          </form>
                        </div>
                      </div>
                      <hr />
                      <div className="text-center">
                        <NavLink className="small" to="/forgot-password">
                          Forgot Password?
                        </NavLink>
                      </div>
                      {/* <hr />
                      <div className="text-center">
                        <NavLink className="small" to="/admin">
                          Admin Login
                        </NavLink>
                      </div> */}
                      <div className="text-center">
                        <span style={{ fontSize: "13px" }}>
                          Don't have an account?{" "}
                        </span>
                        <NavLink className="small" to="/register">
                          Sign Up!
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
