import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { dashboardGraphDetail } from "../redux/adminSlice";
import PatientDashboard from "../ManageDashboard/PatientDashboard";
import DoctorDashboard from "../ManageDashboard/DoctorDashboard";
import { useNavigate } from "react-router";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userAuth } = useSelector((state) => state.authentication);
  const [showSpinner, setShowSpinner] = useState(true);
  const [dashboardDetailDto, setDashboardDetailDto] = useState([]);

  // const fetchDashboardGraphData = async () => {
  //   setShowSpinner(true);
  //   try {
  //     const response = await dispatch(dashboardGraphDetail());

  //     if (dashboardGraphDetail.fulfilled.match(response)) {
  //       console.log("graph data :: ", response?.payload);
  //       setDashboardDetailDto(response?.payload);
  //     }
  //     setShowSpinner(false);
  //   } catch (error) {
  //     console.error("Error fetching graph data:", error);
  //   } finally {
  //     setShowSpinner(false);
  //   }
  // };

  useEffect(() => {
    // fetchDashboardGraphData();
  }, []);

  return (
    <>
      {userAuth?.role === "Patient" && <PatientDashboard />}
      {(userAuth?.role === "SuperAdmin" ||
        userAuth?.role === "Coach" ||
        userAuth?.role === "Doctor") && <DoctorDashboard />}
    </>
  );
};
export default Dashboard;
