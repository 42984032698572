import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import RouteComp from "./components/routes/route";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "./components/redux/AuthController";

function App() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.authentication);
  console.log("app js user auth: ", userAuth);
  return (
    <>
      <ToastContainer />
      <RouteComp />
    </>
  );
}

export default App;
