import React, { useState } from "react";
import "./Learn.css";

const LearnInfo = ({ tabInfo }) => {
  return (
    <>
      {/* <div className="row">
        <div className="col-2 offset-1 mb-2">
          <button className="btn btn-sm btn-secondary">
            <i className="bi bi-arrow-left"></i> Back
          </button>
        </div>
      </div> */}

      <div className="row">
        <div className="col-10 offset-1 mb-4">
          <form className="user">
            <p type="text" className="block-learn-info">
              {tabInfo}
              {/* Within each category there will be:
          <br />
          <br />
          Program information
          <br />
          <br />
          Patient Protocols
          <br />
          <br />
          Guides on when to book appointments
          <br />
          <br />
          Info on blood testing (what we measure, plus why)
          <br />
          <br />
          Assessments Info (why we need all this info, info privacy and security
          etc)
          <br />
          <br />
          (MVP2 Charting instructions, video tutorials and how tos)
          <br />
          <br />
          Medications used, how to order it etc.
          <br />
          <br />
          Supporting Research */}
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default LearnInfo;
